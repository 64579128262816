import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NOTIFICATION_TYPE } from 'src/app/lib/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class UtilService {

    authToken: any;
    closeResult: any;
    toaster: any;

    constructor(public router: Router,
        private spinner: NgxSpinnerService,
        public toastr: ToastrService) {
        this.toaster = toastr;
    }

    toastMessage(message: any, type: any = '') {
        if (type == NOTIFICATION_TYPE.WARNING)
            this.toastr.warning(message, '', { timeOut: 3000 });
        else if (type == NOTIFICATION_TYPE.ERROR)
            this.toastr.error(message, '', { timeOut: 3000 });
        else
            this.toastr.success(message, '', { timeOut: 3000 });
    }

    showSpinner() {
        this.spinner.show();
    }

    hideSpinner() {
        this.spinner.hide();
    }

    handleErrorAndHideSpinner(error: any) {
        this.spinner.hide();
        console.error(error);
        throw error;
    }
}