<form class="form-horizontal" [formGroup]="contactForm" (submit)="contact()" autocomplete="off">
  <span class="block font-bold text-lg">Say hi to the team</span>
  <div class="form-group">
    <input type="text" pInputText placeholder="Full Name" formControlName="name" required />
    <span class="text-danger"
      *ngIf="contactForm.controls['name'].touched && (contactForm.controls['name'].errors?.['required'])">
      *Required
    </span>
  </div>
  <div class="form-group">
    <input type="text" pInputText placeholder="Email Address" autocomplete="off" formControlName="email"
      (change)="validateEmail()" required>
    <span class="text-danger" *ngIf="contactForm.controls['email'].touched">
      <span *ngIf="(contactForm.controls['email'].errors?.['required'])"> *Required </span>
      <span *ngIf="(contactForm.controls['email'].errors?.['email'])"> Invalid Email </span>
      <span *ngIf="(contactForm.controls['email'].errors?.['valError'])"> Public Email are not
        allowed</span>
    </span>
  </div>
  <div class="form-group">
    <textarea class="form-control" id="message" formControlName="message" pInputTextarea rows="7"
      placeholder="Your Message"></textarea>
  </div>
  <div class="form-group">
    <ngx-recaptcha2 #captchaElem [siteKey]="recaptchaKey" [useGlobalDomain]="false" formControlName="recaptcha">
    </ngx-recaptcha2>
  </div>
  <button type="submit" [disabled]="!this.contactForm.valid"
    class="p-button w-full font-bold bg-blue-600 text-white mt-2 flex justify-content-center mx-auto select-none border-none hover:bg-blue-400">
    SEND A MESSAGE
  </button>
</form>