import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from "@angular/forms";
import { APIService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { apiEndpoint, EMAIL_BLACKLIST, httpMethod } from 'src/app/lib/constants';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public contactForm: any;
  public errorMessage: any = '';
  public recaptchaKey: any = environment.googleRecaptchaKey;
  constructor(private formBuilder: FormBuilder,
    private apiService: APIService,
    private utilService: UtilService) { }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  contact() {
    const bodyData = this.contactForm.value;
    this.apiService.httpRequest(httpMethod.POST, apiEndpoint.CONTACT_US, bodyData, true).then((res: any) => {
      if (res?.status_code == 200) {
        this.contactForm.reset();
      } else if (res?.response_error) {
        this.errorMessage = res.message;
      }
      this.utilService.hideSpinner();
    }).catch((error: any) => {
      this.utilService.handleErrorAndHideSpinner(error);
    });
  }

  validateEmail() {
    const emailControl = this.contactForm.controls['email'];
    const email = emailControl.value;
    if (email) {
      if (email.includes("@")) {
        const emails = email.split("@");
        const found = EMAIL_BLACKLIST.some((x: any) => emails.includes(x));
        emailControl.setErrors((found) ? { valError: true } : null);
      } else {
        emailControl.setErrors({ email: true });
      }
    } else {
      emailControl.setErrors({ required: true });
    }
  }
}
