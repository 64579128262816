import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
import { NOTIFICATION_TYPE, httpMethod } from '../lib/constants';

@Injectable()
export class APIService {

  public APIBaseUrl = environment.APIBaseUrl;
  private Endpoint = environment.Endpoint;
  private version = environment.version;
  private baseURL;
  private headerOptions: any;

  constructor(public http: HttpClient, public utilService: UtilService) {
    this.baseURL = this.APIBaseUrl + this.Endpoint + this.version;
  }

  httpRequest(method: any, apiEndpoint: any, bodyData: any = {}, isToast: any = false, authToken: any = '') {
    return new Promise((resolve, reject) => {

      this.headerOptions = {
        headers: new HttpHeaders().set('Authorization', authToken ? authToken : environment.publicAuthToken)
      }

      let httpOption: any;
      if (method == httpMethod.GET) {
        httpOption = this.http.get(this.baseURL + apiEndpoint, this.headerOptions)
      } else if (method == httpMethod.POST) {
        httpOption = this.http.post(this.baseURL + apiEndpoint, bodyData, this.headerOptions)
      } else {
        reject('Invalid Request');
      }

      httpOption.subscribe((res: any) => {
        if (isToast)
          this.utilService.toastMessage(res.message, (res.response_error) ? NOTIFICATION_TYPE.ERROR : NOTIFICATION_TYPE.SUCCESS);

        return resolve(res);
      }, (error: any) => {
        if (error?.status == 401 || error?.statusText == 'Unauthorized') {
          this.utilService.toastMessage('Token expired!', NOTIFICATION_TYPE.ERROR);
          return reject(error.statusText);
        }

        return reject(error);
      })
    });
  }
}