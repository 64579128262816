// Import Node Module
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxCaptchaModule } from 'ngx-captcha';

// Import PrimeNG Module
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

// Import Services
import { APIService } from './services/api.service';
import { UtilService } from './services/util.service';

// Import Components
import { AppComponent } from './app.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { LandingComponent } from './components/landing/landing.component';
import { NotfoundComponent } from './components/notfound/notfound.component';

const routes: Routes = [
    { path: '', component: LandingComponent },
    { path: 'notfound', component: NotfoundComponent },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        ContactUsComponent,
        NotfoundComponent
    ],
    imports: [
        RouterModule.forRoot(routes),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule, ReactiveFormsModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        NgxSpinnerModule,
        ButtonModule,
        StyleClassModule,
        InputTextModule, InputTextareaModule,
        NgxCaptchaModule
    ],
    providers: [
        APIService,
        UtilService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }