<div class="relative overflow-hidden flex flex-column justify-content-center">
  <div class="absolute top-0 bg-shape-top bg-no-repeat bg-cover w-full"></div>
  <div class="landing-wrapper z-1">
    <div class="px-4 md:px-8 flex align-items-center justify-content-between relative lg:static"
      style="min-height: 80px" #home id="home">
      <div class="flex gap-2 align-items-center text-white mr-0 lg:mr-6 select-none">
        <img src="images/logo-white.png" class="w-3rem h-2rem" alt="logo-white.png">
        <span class="font-bold text-3xl">SendB2B</span>
      </div>
      <a pRipple class="cursor-pointer block lg:hidden text-white" pStyleClass="@next" enterClass="hidden"
        leaveToClass="hidden" [hideOnOutsideClick]="true">
        <i class="pi pi-bars text-4xl"></i>
      </a>
      <div id="menu"
        class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none md:bg-transparent">
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          <li>
            <a pRipple (click)="scroll(home)" pStyleClass="#menu" enterClass="hidden" enterActiveClass="px-fadein"
              leaveToClass="hidden"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap">
              <span>Home</span>
            </a>
          </li>
          <li class="lg:relative">
            <a pRipple (click)="scroll(features)" pStyleClass="#menu" enterClass="hidden" enterActiveClass="px-fadein"
              leaveToClass="hidden"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap">
              <span>Solutions</span>
            </a>
          </li>
          <li class="lg:relative">
            <a pRipple (click)="scroll(about)" pStyleClass="#menu" enterClass="hidden" enterActiveClass="px-fadein"
              leaveToClass="hidden"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap">
              <span>About</span>
            </a>
          </li>
          <li>
            <a pRipple (click)="scroll(pricing)" pStyleClass="#menu" enterClass="hidden" enterActiveClass="px-fadein"
              leaveToClass="hidden"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap">
              <span>Pricing</span>
            </a>
          </li>
          <li>
            <a pRipple (click)="scroll(faq)" pStyleClass="#menu" enterClass="hidden" enterActiveClass="px-fadein"
              leaveToClass="hidden"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap">
              <span>FAQ</span>
            </a>
          </li>
        </ul>
        <ul
          class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
          <li>
            <a pRipple (click)="scroll(contact)"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap">
              <span>Contact</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="px-4 md:px-8 z-2">
      <div class="grid justify-content-between mb-6 md:mb-8 mt-5">
        <div class="col-12 lg:col-6 text-center lg:text-left flex flex-column gap-3">
          <span class="font-bold text-4xl text-white text-center md:text-left w-full md:w-9">Targeted Direct Mail
            Superior To Digital Channels in Engagement and Results</span>
          <p class="w-full md:w-9 text-center md:text-left font-semibold text-white">SendB2B: The cutting-edge B2B
            direct mail platform that revolutionises your targeted outreach with postcode and SIC code precision.
            Experience the power of pinpoint marketing.</p>
          <a role="button" pRipple (click)="scroll(features)" class=" p-button px-6 py-2 w-15rem flex align-self-center md:align-self-start justify-content-center
            align-items-center surface-section font-bold cursor-pointer border-none hover:bg-primary-100 shadow-3
            md:shadow-none" style="color: var(--primary-800)">LEARN MORE</a>
        </div>
        <div class="col-12 text-center md:text-right lg:col-6">
          <img animateEnter="moveinright" src="images/header-image.png" alt="header-image.png" class="w-full sm:w-auto">
        </div>
      </div>
      <div id="features" class="my-6 md:my-8 py-4" #features>
        <div class="w-full text-center">
          <span class="block font-bold text-5xl mb-2">Why Choose SendB2B?</span>
          <span class="block font-bold text-lg text-color-secondary">Elevate Your B2B Outreach</span>
        </div>
        <div class="grid mt-8">
          <div class="col-12 md:col-6 lg:col-3 text-center px-5">
            <img animateEnter="moveinleft" src="images/icon-devices.svg" alt="Devices" class="w-3rem h-3rem mb-4">
            <span class="text-2xl font-bold block">Precision Targeting</span>
            <span class="font-bold block mt-3 text-color-secondary">Pinpoint your outreach by targeting specific
              postcodes or SIC codes, ensuring your message reaches the right audience.</span>
          </div>
          <div class="col-12 md:col-6 lg:col-3 text-center px-5">
            <img animateEnter="fadeindown" src="images/icon-design.svg" alt="Devices" class="w-3rem h-3rem mb-4">
            <span class="text-2xl font-bold block">Efficient & Cost-Effective</span>
            <span class="font-bold block mt-3 text-color-secondary">Maximize your ROI with cost-effective solutions.
              Letters are only £1 each, with flexible subscription options for greater reach.</span>
          </div>
          <div class="col-12 md:col-6 lg:col-3 text-center px-5">
            <img animateEnter="fadeindown" src="images/icon-document.svg" alt="Devices" class="w-3rem h-3rem mb-4">
            <span class="text-2xl font-bold block">User-Friendly</span>
            <span class="font-bold block mt-3 text-color-secondary">An intuitive interface that makes it easy to
              register, subscribe to target areas, and send out your marketing letters.</span>
          </div>
          <div class="col-12 md:col-6 lg:col-3 text-center px-5">
            <img animateEnter="moveinright" src="images/icon-diamond.svg" alt="Devices" class="w-3rem h-3rem mb-4">
            <span class="text-2xl font-bold block">Responsive Support</span>
            <span class="font-bold block mt-3 text-color-secondary">Although our platform is designed for self-service,
              we offer prompt support for any questions or issues you may have.</span>
          </div>
        </div>
      </div>
      <div id="theming" class="grid row-gap-8 my-6 md:my-8 py-4 justify-content-between align-items-center">
        <div class="col-12 md:col-5 flex-order-0">
          <div class="w-5rem bg-primary mb-2 border-round" style="height: 4px;"></div>
          <span class="text-2xl font-bold block mb-4">Empowering Your B2B Strategy with SendB2B</span>
          <span class="block font-semibold">Take control of your B2B marketing strategy with SendB2B's unparalleled
            targeting features. Our system allows you to reach out to newly incorporated businesses using specific
            postcodes or SIC codes. By focusing your efforts, you not only save time but also resources, ensuring that
            every pound spent is an investment in potential high-quality partnerships.</span>
        </div>
        <div class="col-12 md:col-6 flex-order-1">
          <img animateEnter="moveinright" src="images/feature-image-1.png" class="w-full" alt="Theming">
        </div>
        <div class="col-12 md:col-6 flex-order-3 md:flex-order-2">
          <img animateEnter="moveinleft" src="images/feature-image-2.png" class="w-full" alt="Theming">
        </div>
        <div class="col-12 md:col-5 text-right h-full flex-order-2 md:flex-order-3">
          <div class="w-5rem bg-primary mb-2 border-round ml-auto" style="height: 4px;"></div>
          <span class="text-2xl font-bold block mb-4">Simple Yet Powerful User Experience</span>
          <span class="block font-semibold">Navigating SendB2B is a breeze. From registration to sending out your
            marketing letters, our user-friendly interface ensures that you can effectively execute your B2B strategy
            without a steep learning curve. Use mail credits to easily manage your budget, and let our automated system
            take care of the rest. Simplicity never compromised effectiveness.</span>
        </div>
        <div class="col-12 md:col-5 flex-order-4">
          <div class="w-5rem bg-primary mb-2 border-round" style="height: 4px;"></div>
          <span class="text-2xl font-bold block mb-4">Flexible Plans for Every Need</span>
          <span class="block font-semibold">Whether you're a small business looking to make your mark or a large
            corporation aiming to maintain a competitive edge, SendB2B has a flexible plan for you. With our tiered
            subscription model, from free to Platinum, we ensure that every business can afford the best in B2B
            marketing. Choose your areas and sectors to target, and fine-tune your strategy to meet your business
            objectives.</span>
        </div>
        <div class="col-12 md:col-6 flex-order-5">
          <img animateEnter="moveinright" src="images/feature-image-3.png" class="w-full" alt="Theming">
        </div>
      </div>
      <div id="about" class="card px-4 py-8 md:px-6 lg:px-8" #about>
        <div class="flex flex-wrap mb-4">
          <div class="w-full lg:w-6 pl-0 lg:pr-4">
            <img src="images/about-1.png" alt="Image" class="w-full border-round">
          </div>
          <div class="w-full lg:w-6 pr-0 lg:pl-4 mt-3 lg:mt-0">
            <div class="font-bold text-4xl mb-4 text-900">About us</div>
            <p class="line-height-3 mt-0 mb-3 p-0"> At SendB2B, we are revolutionizing the way businesses communicate
              with each other. Our journey began with a simple yet profound insight: in a world rapidly shifting to
              digital, the impact of a tangible, well-crafted piece of direct mail remains unmatched. This realization
              sparked the creation of SendB2B - a platform dedicated to harnessing the power of physical mail in the
              digital age. </p>
            <p class="line-height-3 mt-0 mb-3 p-0"> Our mission is to bridge the gap between traditional and digital
              marketing strategies. We empower businesses to reach their potential clients through targeted, efficient,
              and creative direct mail campaigns. Utilizing cutting-edge technology, we offer a seamless, automated
              experience from start to finish. Our platform simplifies the direct mail process, enabling businesses to
              create, send, and track their campaigns with unparalleled ease. </p>
            <p class="line-height-3 mt-0 mb-3 p-0"> At the core of SendB2B is our innovative targeting system. We
              understand that relevance is key in any marketing effort. Therefore, we've equipped our platform with
              advanced tools that allow precise targeting based on sectors and geographical locations. This feature
              ensures that your message reaches the right audience, maximizing impact and return on investment. </p>
            <p class="line-height-3 mt-0 mb-3 p-0"> We pride ourselves on being more than just a service provider. We
              are your partner in growth, committed to supporting your business's outreach efforts. With SendB2B, you
              gain access to a team of experts dedicated to ensuring your campaigns are not just sent but also succeed.
              From compliance checks to analytics and insights, we've got every aspect covered. </p>
            <p class="line-height-3 my-0 p-0"> Embracing transparency, flexibility, and innovation, SendB2B is not just
              a platform but a catalyst for your business's success. Whether you're a startup or an established
              enterprise, our scalable solutions are designed to meet your needs. Join us in redefining the landscape of
              B2B communication and unlock the potential of direct mail marketing. </p>
          </div>
        </div>

      </div>
      <div id="pricing" class="my-6 md:my-8 py-4 text-center" #pricing>
        <span class="block font-bold text-5xl mb-3">Our Pricing</span>
        <span class="block font-bold text-color-secondary text-xl">The best price for our customers</span>
        <div class="grid justify-content-center lg:justify-content-between xl:justify-content-center mt-7">
          <div class="col-12 md:col-6 lg:col-4 xl:col-3 lg:p-3 xl:p-5 flex-shrink-0">
            <div class="card py-5 shadow-6 bg-blue-500 text-white">
              <span class="block text-2xl font-bold mb-3">FREE</span>
              <span class="block text-xl font-semibold mb-3">Priced at</span>
              <span class="block text-xl font-bold">£0</span>
              <ul class="list-none mx-0 mt-3 p-0">
                <li class="font-semibold">Access to New Company Data</li>
                <li class="mt-3 font-semibold">Target 1 Area</li>
                <li class="mt-3 font-semibold">£1 Per Letter Sent</li>
              </ul>
              <a href="https://portal.sendb2b.co.uk/user/register" pRipple
                class="p-button w-10 font-bold bg-blue-600 text-white mt-4 flex justify-content-center mx-auto select-none border-none hover:bg-blue-400">REGISTER
                NOW</a>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-4 xl:col-3 lg:p-3 xl:p-5 flex-shrink-0">
            <div class="card py-5 shadow-6 bg-blue-500 text-white">
              <span class="block text-2xl font-bold mb-3">BRONZE</span>
              <span class="block text-xl font-semibold mb-3">Priced at</span>
              <span class="block text-xl font-bold">£20</span>
              <ul class="list-none mx-0 mt-3 p-0">
                <li class="font-semibold">Access to New Company Data</li>
                <li class="mt-3 font-semibold">Target 5 Areas</li>
                <li class="mt-3 font-semibold">£1 Per Letter Sent</li>
              </ul>
              <a href="https://portal.sendb2b.co.uk/user/register" pRipple
                class="p-button w-10 font-bold bg-blue-600 text-white mt-4 flex justify-content-center mx-auto select-none border-none hover:bg-blue-400">REGISTER
                NOW</a>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-4 xl:col-3 lg:p-3 xl:p-5 flex-shrink-0">
            <div class="card py-5 shadow-6 text-white"
              style="background-image: linear-gradient(45deg, var(--blue-700) 0%, var(--blue-500) 100%);">
              <span class="block text-2xl font-bold mb-3">SILVER</span>
              <span class="block text-xl font-semibold mb-3">Priced at</span>
              <span class="block text-xl font-bold">£100</span>
              <ul class="list-none mx-0 mt-3 p-0">
                <li class="font-semibold">Access to New Company Data</li>
                <li class="mt-3 font-semibold">Target 20 Areas & 1 Sector</li>
                <li class="mt-3 font-semibold">£1 Per Letter Sent</li>
              </ul>
              <a href="https://portal.sendb2b.co.uk/user/register" pRipple
                class="p-button w-10 font-bold bg-blue-600 text-white mt-4 flex justify-content-center mx-auto select-none border-none hover:bg-blue-400">REGISTER
                NOW</a>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-4 xl:col-3 lg:p-3 xl:p-5 flex-shrink-0">
            <div class="card py-5 shadow-6 bg-blue-500 text-white">
              <span class="block text-2xl font-bold mb-3">GOLD</span>
              <span class="block text-xl font-semibold mb-3">Priced at</span>
              <span class="block text-xl font-bold">£200</span>
              <ul class="list-none mx-0 mt-3 p-0">
                <li class="font-semibold">Access to New Company Data</li>
                <li class="mt-3 font-semibold">Target 50 Areas & 5 Sectors</li>
                <li class="mt-3 font-semibold">£1 Per Letter Sent</li>
              </ul>
              <a href="https://portal.sendb2b.co.uk/user/register" pRipple
                class="p-button w-10 font-bold bg-blue-600 text-white mt-4 flex justify-content-center mx-auto select-none border-none hover:bg-blue-400">REGISTER
                NOW</a>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-4 xl:col-3 lg:p-3 xl:p-5 flex-shrink-0">
            <div class="card py-5 shadow-6 bg-blue-500 text-white">
              <span class="block text-2xl font-bold mb-3">PLATINUM</span>
              <span class="block text-xl font-semibold mb-3">Priced at</span>
              <span class="block text-xl font-bold">£500</span>
              <ul class="list-none mx-0 mt-3 p-0">
                <li class="font-semibold">Access to New Company Data</li>
                <li class="mt-3 font-semibold">Target Unlimited Areas & Sectors</li>
                <li class="mt-3 font-semibold">£1 Per Letter Sent</li>
              </ul>
              <a href="https://portal.sendb2b.co.uk/user/register" pRipple
                class="p-button w-10 font-bold bg-blue-600 text-white mt-4 flex justify-content-center mx-auto select-none border-none hover:bg-blue-400">REGISTER
                NOW</a>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="faq" #faq>
        <div class="text-900 font-bold text-xl mb-3">Frequently Asked Questions</div>
        <div class="text-600 line-height-3">
          <ol>
            <li>
              <strong>What is SendB2B and how does it work?</strong>
              <br />SendB2B is a direct mail automation platform designed for B2B communications, streamlining the
              process of creating, sending, and tracking direct mail campaigns.
            </li>
            <li>
              <strong>How can I target specific sectors or areas with my campaigns?</strong>
              <br />SendB2B allows you to target specific sectors and areas using our advanced filtering system,
              including options for postcode and SIC code targeting.
            </li>
            <li>
              <strong>Is there a free trial available for SendB2B?</strong>
              <br />Yes, we offer a free trial that lets you explore basic features of the platform before committing to
              a paid plan.
            </li>
            <li>
              <strong>Can I integrate SendB2B with my existing CRM system?</strong>
              <br />Yes, SendB2B offers integration capabilities with various CRM systems for seamless data
              synchronization and campaign management.
            </li>
            <li>
              <strong>How do I track the success of my direct mail campaigns?</strong>
              <br />Our platform provides detailed analytics and tracking features, allowing you to measure the success
              of your campaigns in real-time.
            </li>
            <li>
              <strong>Is it possible to customize the templates for my direct mail?</strong>
              <br />Absolutely, SendB2B offers a range of customizable templates, giving you the freedom to tailor your
              content to suit your brand and message.
            </li>
            <li>
              <strong>What measures does SendB2B take to ensure data privacy and compliance?</strong>
              <br />We adhere to strict data privacy regulations and ensure that all campaigns are compliant with
              industry standards.
            </li>
            <li>
              <strong>Can I automate recurring direct mail campaigns with SendB2B?</strong>
              <br />Yes, our platform supports the automation of recurring campaigns, saving you time and effort in
              managing ongoing communications.
            </li>
            <li>
              <strong>What kind of customer support does SendB2B offer?</strong>
              <br />We provide comprehensive customer support through various channels, including email, phone, and live
              chat, to assist you with any questions or issues.
            </li>
          </ol>
        </div>
      </div>
      <div id="contact" class="grid justify-content-center lg:justify-content-evenly mt-6 md:mt-8 pt-4" #contact>
        <div class="col-12 text-center mb-5 text-white">
          <span class="block text-5xl font-bold mb-3">Let's Get In Touch</span>
          <span class="block text-xl font-bold">Just drop us an email</span>
        </div>
        <div class="col-12 sm:col-7 lg:col-4 lg:px-5">
          <div class="card py-5 flex flex-column gap-4 shadow-4">
            <app-contact-us></app-contact-us>
          </div>
        </div>
        <div class="hidden lg:block lg:col-4 text-white">
          <span class="block lg:text-5xl xl:text-6xl font-bold white-space-nowrap">Feel free to contact us <br />and we
            will get <br />back to you <br />as soon as we can. </span>
          <div class="grid mt-6">
            <div class="col-6">
              <span class="text-2xl font-bold">Opening Hours</span>
              <ul class="list-none mx-0 p-0 mt-3">
                <li class="font-semibold">Monday - Friday</li>
                <li class="font-semibold">9am - 6pm</li>
                <li class="font-semibold">Weekend</li>
                <li class="font-semibold">Closed</li>
              </ul>
            </div>
            <div class="col-6">
              <span class="text-2xl font-bold block">Address</span>
              <span class="font-semibold white-space-nowrap block mt-3"> 01 Data Ltd <br> 124 City Road, <br> London,
                <br> EV1C 2NX </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-items-center justify-content-between px-4 md:px-8 py-4 mt-5">
      <div class="flex gap-2 align-items-center text-white select-none">
        <img src="images/logo-white.png" class="w-3rem h-2rem" alt="logo-white.png">
        <span class="font-bold text-3xl">SendB2B</span>
      </div>
      <div id="menu"
        class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none md:bg-transparent">
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          <li>
            <a href="https://portal.sendb2b.co.uk/terms-conditions" target="_blank" pStyleClass="#menu"
              enterClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap">
              <span>Terms & Conditions</span>
            </a>
          </li>
          <li class="lg:relative">
            <a href="https://portal.sendb2b.co.uk/privacy=policy" target="_blank" pStyleClass="#menu"
              enterClass="hidden" enterActiveClass="px-fadein" leaveToClass="hidden"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap">
              <span>Privacy Policy</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="absolute bottom-0 bg-shape-bottom bg-no-repeat bg-cover w-full"></div>
</div>