<svg width="54px" height="54px" viewBox="0 0 74 64" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" class="flex absolute z-1 mx-6 my-6">
    <title>Shape</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="noun_Diamond_1258501" fill="#FFFFFF" fill-rule="nonzero">
            <path d="" id="Shape"></path>
        </g>
    </g>
</svg>
<div class="px-5 min-h-screen flex justify-content-center align-items-center bg-cover bg-center"
    [style]="{'background-image':'url(images/notfound/bg-404.jpg)'}">
    <div class="z-1 text-center">
        <div class="text-900 font-bold text-white text-8xl mb-4">NOT FOUND</div>
        <p class="line-height-3 text-white mt-0 mb-5 text-700 text-xl font-medium">Requested resource is not available.
        </p>
        <p-button [routerLink]="['/']" styleClass="p-button-primary font-medium p-button-raised">Go to
            Homepage</p-button>
    </div>
</div>