const EMAIL_BLACKLIST: any = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com'];

const NOTIFICATION_TYPE = {
    WARNING: "warning",
    ERROR: "error",
    SUCCESS: 'success'
};

// API Endpoints
const apiEndpoint = {
    // Contact
    CONTACT_US: '/contact_us'
};

const httpMethod = {
    GET: 'get',
    POST: 'post'
}

export {
    EMAIL_BLACKLIST,
    NOTIFICATION_TYPE,
    apiEndpoint,
    httpMethod
}